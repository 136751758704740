<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  PassageWay Web<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    A modified version of the PassageWay Web Vault for PassageWay (an unofficial rewrite of the
    Bitwarden&reg; server).<br />
    PassageWay is not associated with the Vaultwarden or Bitwarden. Vaultwarden is not associated with the Bitwarden&reg; projects nor Bitwarden Inc.
  </div>
</div>
